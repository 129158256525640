import { useEffect } from 'react';
import {
  identityApiRef,
  useApi,
  configApiRef,
} from '@backstage/core-plugin-api';
import Intercom from '@intercom/messenger-js-sdk';
import { shutdown } from '@intercom/messenger-js-sdk';

export const IntercomMessenger = () => {
  const identityApi = useApi(identityApiRef);
  const config = useApi(configApiRef);
  const app_id = config.getString('intercom.appId');

  useEffect(() => {
    const initializeIntercom = async () => {
      const profile = await identityApi.getProfileInfo();
      const userinfo = await identityApi.getBackstageIdentity();

      // eslint-disable-next-line new-cap
      Intercom({
        app_id: app_id, // this isn't a secret, as it's public in the frontend
        user_id: userinfo.userEntityRef,
        name: profile.displayName,
        email: profile.email,
      });
    };

    initializeIntercom();

    // Optional: Cleanup function to shutdown Intercom when the component unmounts
    return () => {
      // eslint-disable-next-line new-cap
      shutdown();
    };
  }, [identityApi, app_id]);

  // Return null or any other JSX if you want to render something
  return null;
};
