import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { playHtApiRef } from './apis/TTSApi';
import { TTSClient } from './apis/TTSClient';

export const playHtPlugin = createPlugin({
  id: 'play-ht',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: playHtApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new TTSClient({ discoveryApi, fetchApi }),
    }),
  ],
});

export const PlayHtPage = playHtPlugin.provide(
  createRoutableExtension({
    name: 'PlayHtPage',
    component: () =>
      import('./components/MainComponent').then(m => m.PlayHTIndex),
    mountPoint: rootRouteRef,
  }),
);
