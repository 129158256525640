import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import { TTSApi } from './TTSApi';

export class TTSClient implements TTSApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async generateSpeech(text: string, id: string): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('play-ht');
    const response = await this.fetchApi.fetch(
      `${baseUrl}/generatespeech?text=${encodeURIComponent(
        text,
      )}&voiceId=${encodeURIComponent(id)}`,
    );
    if (!response.ok) {
      throw new Error(`Failed to generate speech: ${response.statusText}`);
    }
    return await response.json();
  }

  async listVoices(): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('play-ht');
    const response = await this.fetchApi.fetch(`${baseUrl}/listvoices`);
    if (!response.ok) {
      throw new Error(`Failed to list voices: ${response.statusText}`);
    }
    return await response.json();
  }
}
