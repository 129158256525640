import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';
import { createCardExtension } from '@backstage/plugin-home-react';
import type { ToolkitContentProps } from './components/ToolKit';

export const azureHomePrsPlugin = createPlugin({
  id: 'azure-home-prs',
  routes: {
    root: rootRouteRef,
  },
});

export const AzureHomePrsPage = azureHomePrsPlugin.provide(
  createRoutableExtension({
    name: 'AzureHomePrsPage',
    component: () => import('./components/PullRequests/').then(m => m.Content),
    mountPoint: rootRouteRef,
  }),
);

export const HomePageToolkit = azureHomePrsPlugin.provide(
  createCardExtension<ToolkitContentProps>({
    name: 'HomePageToolkit',
    components: () => import('./components/ToolKit'),
  }),
);

export const AzureHomePrsCard = azureHomePrsPlugin.provide(
  createCardExtension({
    name: 'AzureHomePrsCard',
    title: 'Your Open ADO Pull Requests',
    components: () => import('./components/PullRequests'),
  }),
);

export const AzureReviewPrsCard = azureHomePrsPlugin.provide(
  createCardExtension({
    name: 'AzureReviewPrsCard',
    title: 'ADO PRs Awaiting Your Review',
    components: () => import('./components/ReviewRequests'),
  }),
);
