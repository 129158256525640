import { createApiRef } from '@backstage/core-plugin-api';

export const playHtApiRef = createApiRef<TTSApi>({
  id: 'plugin.play-ht.service',
});

export interface TTSApi {
  generateSpeech(text: string, id: string): Promise<any>;

  listVoices(): Promise<any>;
}
